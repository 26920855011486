import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"

import SEO from "../components/globals/seo"
import Layout from "../components/containers/layout"
import { Relative } from "../components/containers/position"
import Container from "../components/containers/container"
import Text from "../components/typography/text"
import Link from "../components/links/link"
import ContactFormResponse from "../components/forms/contactFormResponse"

import { createHubspotTicket } from "../actions/hubspotFormActions"

const TempLink = styled(Link)`
  font-family: ${p => p.theme.headingFont};
  color: ${p => p.theme.white};
 
  :hover {
    color: ${p => p.theme.green};
  }
`;

const ContactPage = ({ theme }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formFired, setFormFired] = useState(``);

  let responseComponent;
  if (formFired === `success`) {
    responseComponent = (
      <ContactFormResponse setFormFired={() => setFormFired(``)} />
    )
  } else if (formFired === `error`) {
    responseComponent = (
      <ContactFormResponse setFormFired={() => setFormFired(``)} error />
    )
  }

  const handleSubmit = values => {
    setIsLoading(true);

    createHubspotTicket(values)
      .then(() => {
        setFormFired(`success`);
        setIsLoading(false);
        window.scrollTo(0, 0)
      })
      .catch(() => {
        setFormFired(`error`);
        setIsLoading(false);
        window.scrollTo(0, 0)
      })
  };

  return (
    <Layout bg={theme.blueDark} navbarPadOnly>
      <SEO
        title="Contact Us"
        description="Getting in touch with the TopTekkers team."
      />

      <Relative
        borderBottom="1px solid rgba(255, 255, 255, 0.1)"
        pb={[120, null, null, 240]}
      >
        <Container maxWidth="800px" mt="70px">
          <Text color={theme.white} textAlign="center">
            Need help? Take a look at our <TempLink to="/faq/">FAQs</TempLink>
            {` `}
            for answers to the most common questions, or if you can’t find the
            answer contact us on{` `}
            <TempLink
              as="a"
              href="mailto:help@toptekkers.club?subject=Contact page user enquiry."
              className="bold"
            >
              help@toptekkers.club
            </TempLink>
          </Text>
          {/* {formFired === `` ? (
            <ContactForm onSubmit={handleSubmit} loading={isLoading} />
          ) : (
            responseComponent
          )} */}
        </Container>
      </Relative>
    </Layout>
  )
};

ContactPage.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(ContactPage)